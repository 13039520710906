import { template as template_ca1f386c8b1740628439be30789663d2 } from "@ember/template-compiler";
const FKText = template_ca1f386c8b1740628439be30789663d2(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
