import { template as template_e3b59f05d8bb496cb04f8d3f6c89379c } from "@ember/template-compiler";
const FKControlMenuContainer = template_e3b59f05d8bb496cb04f8d3f6c89379c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
