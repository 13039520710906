import { template as template_33377705b3024cd8baed555fe63e2c23 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_33377705b3024cd8baed555fe63e2c23(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
