import { template as template_c006678a63b44d43860a31b6421c8987 } from "@ember/template-compiler";
const FKLabel = template_c006678a63b44d43860a31b6421c8987(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
