import { template as template_739257cffa0d470eb7ddcf9167379110 } from "@ember/template-compiler";
const SidebarSectionMessage = template_739257cffa0d470eb7ddcf9167379110(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
